










































































































































































import Vue from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import operatingModes from '@/config/operating-modes';
import popUpModal from '@/components/form/pop-up-modal.vue';
import { CASE_STATUS } from '@/constant/constants';
import FormSwitch from '@/components/form/form-switch.vue';
import IRiskAssesment from '@/model/IRiskAssesment';
import errorMessage from '@/components/form/error-message.vue';


export default Vue.extend({
  name: 'exhibition_insurance',
  props: ['assesment', 'insuranceData', 'caseStatus', 'insuranceKey', 'isAPIObserver', 'AMSAPISendStatus'],
  components: {
    vSelect,
    popUpModal,
    FormSwitch,
    errorMessage,
  },
  data() {
    return {
      processName: '',
      operatingModes,
      operatingMode: '',
      isSelectOpen: false,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      type: 'main',
      nextPath: '',
      key: 'ANNUAL_INSURANCE',
      remarks: '',
      haveBussiness: false,
      haveTransport: false,
      componentLoaded: false,
      isUpdated: false,
    };
  },
  watch: {
    assesment: {
      deep: true,
      handler(value: IRiskAssesment) {
        if (!this.isUpdated) {
          this.setValues();
          this.isUpdated = true;
          // this.key = this.insuranceKey;
        }
        return value;
      },
    },
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    changeData(): any {
      return [this.key, this.haveTransport, this.haveBussiness, this.remarks, this.operatingMode].join();
    },
    isBARTTypeDL(): boolean {
      return (this.operatingMode as any)['BART-Gruppe'] === 'DL';
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_READY;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
  },
  methods: {
    onNextClick() {
      this.$router.push({
        name: this.nextPath,
        params: {
          id: this.$route.params.id,
        },
      });

      if (
        this.nextPath.length !== 0 &&
        this.operatingMode.length !== 0 &&
        this.processName.length !== 0
      ) {
        this.$emit(
          'risk-assesment-change',
          {
            operatingMode: this.operatingMode,
            remarks: this.remarks,
            processName: this.processName,
            type: this.type,
            haveTransport: this.haveTransport,
            haveBussiness: this.haveBussiness,
          },
          this.key,
        );
      }
    },
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    onDropDownClick() {
      this.isSelectOpen = !this.isSelectOpen;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.operatingModeRef as any).open = this.isSelectOpen;
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    onChange(path: string, key: string) {
      this.nextPath = path;
      this.key = key;
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    setValues() {
      this.type = (this.assesment as IRiskAssesment).type || 'main';
      this.operatingMode = (this.assesment as IRiskAssesment).operatingMode || '';
      this.remarks = (this.assesment as IRiskAssesment).remarks || '';
      this.processName = (this.assesment as IRiskAssesment).processName || '';
      this.haveTransport = (this.assesment as IRiskAssesment).haveTransport || false;
      this.haveBussiness = (this.assesment as IRiskAssesment).haveBussiness || false;
      this.key = this.insuranceKey ? this.insuranceKey : '';
      this.setNextPath(this.key);
    },
    setNextPath(key: string) {
      switch (key) {
        case 'ANNUAL_INSURANCE':
          this.nextPath = 'annual-insurance';
          break;
        case 'SHORT_TERM_INSURANCE':
          this.nextPath = 'short-term-insurance';
          break;
        default:
          this.nextPath = '';
          break;
      }
    },
  },
  mounted() {
    this.setValues();
    if (this.isDisableAll) {
      this.disableAllInputs();
      this.onNextClick();
    }
    this.$emit(
      'on-key-change',
      this.key,
    );
  },
  beforeUpdate() {
    this.componentLoaded = true;
  },
});
