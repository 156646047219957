





















































































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import CountriesList from '@/components/form/countries-list.vue';
import COUNTRIES from '@/config/countires-list';
import errorMessage from '@/components/form/error-message.vue';
import priceCalculator from '@/service/price-calculator';


export default Vue.extend({
  props: ['assesment', 'isAPIObserver', 'insuranceData', 'caseStatus'],
  name: 'valor-insurance-compact',
  components: {
    FormSwitch,
    'countries-list': CountriesList,
    errorMessage,
  },
  data() {
    return {
      aShop: true,
      pureManufacturing: true,
      alarmSystem: false,
      name: '',
      street: '',
      postCode: '',
      place: '',
      country: 'Deutschland',
      totalSales: 0,
      riskAddress: 'DE',
      safe: true,
      safeList: [
        {
          key: 'LessThanBmax',
          name: 'Geringer als „B“ max. VSu = 0 EUR',
          price: 0,
          val: 'A',
        },
        {
          key: 'ThanBmax',
          name: '„B“ max. VSu = 100.000 EUR',
          price: 100000,
          val: 'B',
        },
        {
          key: 'EURO0max',
          name: 'EURO 0 max. VSu = 120.000 EUR',
          price: 120000,
          val: 'C',
        },
        {
          key: 'EUROImax',
          name: 'EURO I max. VSu = 200.000 EUR',
          price: 200000,
          val: 'D',
        },
        {
          key: 'BetterThanEUROImax',
          name: 'Besser als EURO I max. VSu = 200.000 EU',
          price: 200000,
          val: 'E',
        },
      ],
      safe1: 'A',
      safe2: 'A',
      sefeOverhungry: '',
    };
  },
  methods: {
    getCityByZipCode(event: any) {
      priceCalculator.checkZipCode(this.postCode).then((response) => {
        this.place = response.data.postalCode.city;
      });
    },
    selectRiskAddress() {
      const index = COUNTRIES.findIndex((country) => this.country === country.Land);
      this.riskAddress = COUNTRIES[index]['ISO-Code'];
    },
    onSubmit() {
      const data = {
        aShop: this.aShop,
        pureManufacturing: this.pureManufacturing,
        alarmSystem: this.alarmSystem,
        name: this.name,
        street: this.street,
        postCode: this.postCode,
        place: this.place,
        country: this.country,
        totalSales: this.totalSales,
        safe: this.safe,
        safe1: this.safe1,
        safe2: this.safe2,
      };
      if (this.totalSales >= 100000) {
        const priceData = {
          BetriebsartValoren: this.assesment.operatingMode.BART_TEXT,
          Ladengeschäft: this.aShop,
          Herstellungsbetrieb: this.pureManufacturing,
          Risikoanschrift: this.riskAddress,
          Versicherungssumme: this.totalSales,
          // Wertschutzbehältnis: this.safe,
          Wertschutzbehältnis1: this.safe1,
          Wertschutzbehältnis2: this.safe2,
          MeldeAnlage: this.alarmSystem,
          Geltungsbereich: this.country,
        };
        this.$emit('data-changed', '20837dc0-8918-4d4c-8744-c4998522beed', priceData, data);
      } else {
        const priceData = {
          BetriebsartValoren: this.assesment.operatingMode.BART_TEXT,
          Ladengeschäft: this.aShop,
          Herstellungsbetrieb: this.pureManufacturing,
          Risikoanschrift: this.riskAddress,
          Versicherungssumme: this.totalSales,
          Wertschutzbehältnis: this.safe,
          MeldeAnlage: this.alarmSystem,
          Geltungsbereich: this.country,
        };
        this.$emit('data-changed', '20837dc0-8918-4d4c-8744-c4998522beed', priceData, data);
      }
    },
  },
  computed: {
    priceMoreHundredThousand(): boolean {
      if (this.totalSales >= 100000) {
        return true;
      }
      return false;
    },
    priceLessHundredThousand(): boolean {
      if (this.totalSales < 100000) {
        return true;
      }
      return false;
    },
    priceLessTwoHundredThousand(): boolean {
      if (this.totalSales > 200000) {
        return true;
      }
      return false;
    },
  },
});
