

















































































































































































































































































import Vue from 'vue';
import operatingModes from '@/config/operating-modes';
import popUpModal from '@/components/form/pop-up-modal.vue';
import FormSwitch from '@/components/form/form-switch.vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { CASE_STATUS } from '@/constant/constants';
import DatePicker from '@/components/form/date-picker.vue';
import IRiskAssesment from '@/model/IRiskAssesment';
import errorMessage from '@/components/form/error-message.vue';

export default Vue.extend({
  name: 'market-concept-insurance',
  props: ['assesment', 'insuranceKey', 'caseStatus', 'insuranceData', 'isAPIObserver', 'AMSAPISendStatus'],
  components: {
    FormSwitch,
    popUpModal,
    vSelect,
    DatePicker,
    errorMessage,
  },
  data(): any {
    return {
      scopeSelected: true,
      processName: '',
      operatingModes,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      isSelectOpen: false,
      operatingMode: '',
      remarks: '',
      haveBussiness: false,
      haveTransport: false,
      startOfContract: '',
      endOfContract: '',
      scope: '',
      marketName: '',
      MarkerIsGuarded: false,
      RiskOfTerrorism: false,
      numberOfMarketHutsToBeInsured: 0,
      amountOfInsuranceMarketHuts: 0,
      goodsToBeInsured: '',
      amountOfInsuranceGoods: 0,
      componentLoaded: false,
    };
  },
  methods: {
    scrollToElement() {
      const el = this.$el.getElementsByClassName('priceError')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    onDropDownClick() {
      this.isSelectOpen = !this.isSelectOpen;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.operatingModeRef as any).open = this.isSelectOpen;
    },
    onNextClick() {
      if (this.scope === '') {
        this.scopeSelected = false;
        this.scrollToElement();
        return;
      }
      this.$emit(
        'risk-assesment-change',
        {
          operatingMode: this.operatingMode,
          remarks: this.remarks,
          haveTransport: this.haveTransport,
          haveBussiness: this.haveBussiness,
        },
      );
      const data = {
        processName: this.processName,
        startOfContract: this.startOfContract,
        endOfContract: this.endOfContract,
        scope: this.scope,
        marketName: this.marketName,
        MarkerIsGuarded: this.MarkerIsGuarded,
        RiskOfTerrorism: this.RiskOfTerrorism,
        numberOfMarketHutsToBeInsured: this.numberOfMarketHutsToBeInsured,
        amountOfInsuranceMarketHuts: this.amountOfInsuranceMarketHuts,
        goodsToBeInsured: this.goodsToBeInsured,
        amountOfInsuranceGoods: this.amountOfInsuranceGoods,
        totalInsuredAmount: this.totalInsuredAmount,
      };
      const priceData = {
        Risiken: [
          {
            ID: 'Markthütte',
            Versicherungssumme: this.amountOfInsuranceMarketHuts,
          },
          {
            ID: 'Waren/Güter',
            Versicherungssumme: this.amountOfInsuranceGoods,
          },
        ],
        Bezeichnung: this.processName,
        Bewachung: this.MarkerIsGuarded,
        Terror: this.RiskOfTerrorism,
        Beginn: this.startOfContract,
        Ablauf: this.endOfContract,
        RisikoAnschrift: this.scope,
      };
      this.$emit(
        'data-changed',
        'ba746997-2934-4231-a7b7-de80f90d283f',
        priceData,
        data,
        'MARKET_CONCEPT_INSURANCE',
      );
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    setValues(formData: any) {
      this.operatingMode = (formData.assesment as IRiskAssesment).operatingMode || '';
      this.processName = formData.assesment.processName || '';
      this.startOfContract = new Date(formData.insuranceData.startOfContract) || '';
      this.endOfContract = new Date(formData.insuranceData.endOfContract) || '';
      this.scope = formData.insuranceData.scope || '';
      this.marketName = formData.insuranceData.marketName || '';
      this.MarkerIsGuarded = formData.insuranceData.MarkerIsGuarded || false;
      this.RiskOfTerrorism = formData.insuranceData.RiskOfTerrorism || false;
      this.numberOfMarketHutsToBeInsured = formData.insuranceData.numberOfMarketHutsToBeInsured || 0;
      this.amountOfInsuranceMarketHuts = formData.insuranceData.amountOfInsuranceMarketHuts || 0;
      this.goodsToBeInsured = formData.insuranceData.goodsToBeInsured || '';
      this.amountOfInsuranceGoods = formData.insuranceData.amountOfInsuranceGoods;
      this.haveBussiness = formData.insuranceData.haveBussiness || false;
      this.haveTransport = formData.insuranceData.haveTransport || false;
      this.remarks = formData.insuranceData.remarks || '';
    },
  },
  computed: {
    changeData(): any {
      return [this.processName, this.startOfContract, this.endOfContract, this.scope, this.marketName, this.MarkerIsGuarded, this.RiskOfTerrorism, this.numberOfMarketHutsToBeInsured, this.amountOfInsuranceMarketHuts, this.goodsToBeInsured, this.amountOfInsuranceGoods, this.amountOfInsuranceGoods, this.haveTransport, this.haveBussiness, this.remarks, this.operatingMode].join();
    },
    isBARTTypeDL(): boolean {
      return (this.operatingMode as any)['BART-Gruppe'] === 'DL';
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_READY;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
    totalInsuredAmount(): number {
      return this.amountOfInsuranceMarketHuts + this.amountOfInsuranceGoods;
    },
  },
  watch: {
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (this.$attrs.formData) {
      this.setValues(this.$attrs.formData);
    } else if (this.insuranceData) {
      const formData = {
        insuranceData: this.insuranceData,
        assesment: this.assesment,
      };
      this.setValues(formData);
    }
    this.$emit(
      'risk-assesment-change',
      {
        operatingMode: this.operatingMode,
        remarks: this.remarks,
        haveTransport: this.haveTransport,
        haveBussiness: this.haveBussiness,
      },
      'MARKET_CONCEPT_INSURANCE',
    );
  },
  beforeUpdate() {
    this.componentLoaded = true;
  },
});
